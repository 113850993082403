<template>
  <!-- 活动信息二级页面 -->
  <div class="twoPage">
    <!-- 头部 -->
    <swipeTop :ZHtitle="ZHtitle" :ENtitle="ENtitle" :top_img="top_img" />
    <!-- 搜索 -->
    <seachVue />
    <!-- 标签 -->
    <!-- <tag :tagList="tagList"></tag> -->
    <classifyList :toptype="toptype"></classifyList>
    <!-- 内容 -->
    <div class="content-box" style="padding: 4px 20px;overflow: scroll">
      <p style="text-indent:1.5rem;letter-spacing: 1px;font-size: 0.7rem;line-height: 20px">
        赛默飞世尔科技是赋能科技进步的全球领导者。赛默飞中国临床诊断事业部，借助赛默飞在多个领域的深厚沉淀，聚焦临床诊断，为医院和科研单位提供从原料到平台的临床诊断解决方案，携手客户满足临床需求，让世界变得更健康!
      </p>
      <div
        @click="toThree(item)"
        class="item"
        v-for="(item, index) in subMenuList"
        :key="index"
        v-bind:style="{ backgroundImage: 'url(' + item.pic + ')' }"
      >
        <span>{{ item.menuName }}</span>
        <div class="icon-box">
          <img width="17px" src="../../assets/img/arrowhead.png" alt="">
          <img width="17px" src="../../assets/img/arrowheadTwo.png" alt="">
          <!-- <van-icon class="icon-right" name="arrow" />
          <van-icon name="arrow" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import tag from "../../components/tag";
import classifyList from "@/components/classifyList.vue";
import FloatingBallVue from "../../components/FloatingBall.vue";
import { genMenuInfo, pinpai } from "@/api/path";
export default {
  components: {
    swipeTop,
    seachVue,
    tag,
    classifyList,
    FloatingBallVue,
  },
  data() {
    return {
      ZHtitle: "", // 页面中文标题
      ENtitle: "", // 页面英文标题
      subMenuList: [], // 分类
      toptype: [], // 导航栏内容
      top_img: "", // 头部背景图
      menuId: this.$route.query.mid,
      tagList: [
        {
          tid: 0,
          text: "公司介绍",
        },
        {
          tid: 1,
          text: "微生物检测",
        },
        {
          tid: 2,
          text: "过敏原检测",
        },
        {
          tid: 3,
          text: "移植诊断",
        },
      ],
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {
    // 监听返回事件
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.backButton, false);
    }
  },
  methods: {
    // 获取数据
    getInfo() {
      pinpai(this.menuId)
        .then((result) => {
          this.ZHtitle = result.data.menuName;
          this.ENtitle = result.data.menuEnglish;
          this.top_img = result.data.background;
          this.subMenuList = result.data.subMenuList;
          this.toptype = result.data.classificationMessageAlways;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 前往三级页面
    toThree(item) {
      switch (item.menuId) {
        case 184:
          this.$router.push({
            path: "/levelFivePagePPJS",
            query: { menuId: item.menuId, checkindex: item.menuId },
          });
          break;
        case 187:
          this.$router.push({
            path: "/levelFivePagePPJS",
            query: { menuId: item.menuId, checkindex: item.menuId },
          });
          // this.$router.push({
          //   path: "/levelFivePage",
          //   query: { menuId: item.menuId },
          // });
          break;
        case 228:
          this.$router.push({
            path: "/levelFivePagePPJS",
            query: { menuId: item.menuId, checkindex: item.menuId },
          });
          break;
        default:
          if (item.subMenuList.length === 1) {
            this.$router.push({
              path: "/levelFivePagePPJS",
              query: {
                menuId: item.subMenuList[0].menuId,
                checkindex: item.menuId,
              },
            });
          }
          // this.$router.push({
          //   path: "/levelThreePage",
          //   query: { menuId: item.menuId },
          // });
          break;
      }
    },
    // 返回事件处理
    backButton() {
      this.$router.push({
        path: "/",
      });
    },
  },
  destroyed() {
    // 销毁页面返回事件
    window.removeEventListener("popstate", this.backButton, false);
  },
  touchFunc() {},
};
</script>

<style lang="scss" scoped>
.twoPage {
  height: 100vh;
  .content-box {
    height: 55.8%;
    color: white;
    background-color: #9d0a12;
    .item {
      height: 5.7vh;
      display: flex;
      background-size: cover;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      padding: 20px 20px 0 0;
      font-family: 'FZZZH',serif;
      margin-top: 25px;
      span{
        width: 60%;
        margin-left: 20px;
        font-size: 0.8rem;
        font-weight: bolder;
      }
      .icon-box{
        display: flex;
        justify-content: end;
      }
      .icon-right {
        margin-right: -20px;
        opacity: 0.3;
      }
    }
  }
}
</style>
